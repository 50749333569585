import React, { useState } from "react";
import { graphql } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "components/Layout/Layout";
import ContactUsContents from "components/ContactUsContents/ContactUsContents";
import SupportForm from "components/SupportForm/SupportForm";
import PageContext from "contexts/PageContext";
import SupportLandingSection from "components/SupportLanding/SupportLandingSection";
import { KOMODO } from "store/constants/hotel";
import ButtonNav from "components/Design/Button/NavButton";
import Icon from "components/CommonIcon/Icon";
import ReachUs from "components/ReachUs/ReachUs";
import useQueryParams from "hooks/useQueryParams";
import { WECHAT_LINK } from "store/constants/link";
import { whatsAppUrl } from "utils/whatsAppUrl";
import { transformObject } from "utils/transformv4";

const Support = ({ data, location }) => {
  const { reason, destination } = useQueryParams();
  const { language } = useI18next();
  const { t } = useTranslation(["homepage", "support"]);
  const [supportReason, setSupportReason] = useState(reason);
  const [supportDestination, setSupportDestination] = useState(destination);
  const {
    cms,
    allNavigationMenuJson: {
      nodes: [navigations],
    },
    allSocialMediaJson: {
      nodes: [socialMediaList],
    },
  } = data;

  const {
    sections: [destinations],
    hotelsByLocale: [hotel],
    pagesByLocale: [{ seo_tags }],
    activitiesByLocale: allActivities,
    supportDrawer: [supportDrawer],
    supportRecipientEmails: recipientEmail,
  } = transformObject(cms);

  const supportDrawerData = {
    ...supportDrawer,
    hotel: hotel,
  };

  const allWeddingPackages = hotel.wedding_venues
    .map((venue) => venue.wedding_packages)
    .flat();

  const weddingPackages = allWeddingPackages.filter(
    (weddingPackage, index, array) =>
      array.findIndex((t) => t.title_en === weddingPackage.title_en) === index
  );

  const reachUsLink =
    language === "zh"
      ? WECHAT_LINK
      : whatsAppUrl(hotel.whatsapp_contact, hotel.custom_whatsapp_message);

  return (
    <PageContext.Provider
      value={{
        showBannerPadding: true,
        reason: supportReason,
        destination: supportDestination,
      }}>
      <Layout
        destination={t("Komodo")}
        seoTitle={t("Ayana Support")}
        seoTags={seo_tags}
        destinationCards={destinations.images}
        banner={true}
        showBanner={true}
        navigations={navigations}
        hotel={hotel}
        socialMedia={socialMediaList}
        socialMediaLabel={t("Connect with Ayana Komodo")}
        supportDrawer={supportDrawerData}>
        <SupportLandingSection
          title={t("Ayana Support")}
          contactUsContents={<ContactUsContents hotels={hotel.offices} />}
          otherContents={
            <>
              <ReachUs supportReachUsLink={reachUsLink} />
              <ButtonNav
                href="/labuan-bajo/komodo/faq"
                prefixIcon={<Icon name="comment" />}
                label={t("FAQ")}
              />
            </>
          }
          supportForm={
            <SupportForm
              reason={reason}
              setReason={setSupportReason}
              destination={destination}
              setDestination={setSupportDestination}
              navigateBackTo="/labuan-bajo/komodo/thank-you"
              activities={allActivities}
              weddingVenues={hotel.wedding_venues}
              weddingPackages={weddingPackages}
              location={KOMODO}
              recipients={recipientEmail}
            />
          }
        />
      </Layout>
    </PageContext.Provider>
  );
};

export const query = graphql`
  query KomodoSupport($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNavigationMenuJson(
      filter: { hotel: { eq: "AYANA Komodo Waecicu Beach" } }
    ) {
      ...LocalNavigation
    }
    allSocialMediaJson(
      filter: { hotel: { eq: "AYANA Komodo Waecicu Beach" } }
    ) {
      nodes {
        hotel
        socmed {
          link {
            en
            ja
          }
          iconName
        }
      }
    }
    cms {
      hotelsByLocale(language: $language, where: { id: 4 }) {
        data {
          id
          attributes {
            title
            email
            phone
            name
            whatsapp_contact
            custom_whatsapp_message
            location
            ...CmsHotelLogo_v4
            offices(sort: "sequence") {
              ...CmsOffices_v4
            }
            wedding_venues(sort: "sequence") {
              data {
                id
                attributes {
                  title
                  title_en
                  wedding_packages {
                    data {
                      id
                      attributes {
                        title
                        title_en
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      pagesByLocale(language: $language, where: { name: "Komodo_Support" }) {
        data {
          id
          attributes {
            ...CmsPageSeoTags_v4
          }
        }
      }
      activitiesByLocale(language: $language, where: { kind: "BOAT_TRIP" }) {
        data {
          id
          attributes {
            title
            title_en
          }
        }
      }
      sections(filters: { name: { eq: "Komodo_Footer_Destination" } }) {
        ...NavSections
      }
      supportDrawer: sections(filters: { name: { eq: "KD_Support_Drawer" } }) {
        ...NavSections
      }
      supportRecipientEmails(filters: { location: { eq: "KOMODO" } }) {
        data {
          id
          attributes {
            reason
            location
            emails {
              id
              language
              email
              hotel {
                data {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default Support;
